export const SUBJECTS_LIST = [
  {
    title: 'Allemand',
    url: '/cours/allemand',
  },
  {
    title: 'Anglais',
    url: '/cours/anglais',
  },
  {
    title: 'Biologie',
    url: '/cours/biologie',
  },
  {
    title: 'Chimie',
    url: '/cours/chimie',
  },
  {
    title: 'Droit',
    url: '/cours/droit',
  },
  {
    title: 'Économie',
    url: '/cours/economie',
  },
  {
    title: 'Espagnol',
    url: '/cours/espagnol',
  },
  {
    title: 'Français',
    url: '/cours/francais',
  },
  {
    title: 'Histoire',
    url: '/cours/histoire',
  },
  {
    title: 'Informatique',
    url: '/cours/informatique',
  },
  {
    title: 'Italien',
    url: '/cours/italien',
  },
  {
    title: 'Langues',
    url: '/cours/langues',
  },
  {
    title: 'Maths',
    url: '/cours/maths',
  },
  {
    title: 'Médecine',
    url: '/cours/medecine',
  },
  {
    title: 'Orthographe',
    url: '/cours/orthographe',
  },
  {
    title: 'Philosophie',
    url: '/cours/philosophie',
  },
  {
    title: 'Physique',
    url: '/cours/physique',
  },
  {
    title: 'SES',
    url: '/cours/ses',
  },
  {
    title: 'Soutien Scolaire',
    url: '/cours/soutien-scolaire',
  },
  {
    title: 'SVT',
    url: '/cours/svt',
  },
];

export const LEVELS_LIST = [
  {
    title: 'BTS',
    url: '/cours/toute-matiere/france/bts',
  },
  {
    title: 'Collège',
    url: '/cours/toute-matiere/france/college',
  },
  {
    title: 'Licence',
    url: '/cours/toute-matiere/france/licence',
  },
  {
    title: 'Lycée',
    url: '/cours/toute-matiere/france/lycee',
  },
  {
    title: 'Master',
    url: '/cours/toute-matiere/france/master',
  },
  {
    title: 'Médecine',
    url: '/cours/toute-matiere/france/medecine',
  },
  {
    title: 'MPSI',
    url: '/cours/toute-matiere/france/mpsi',
  },
  {
    title: 'Première',
    url: '/cours/toute-matiere/france/premiere',
  },
  {
    title: 'Prépa',
    url: '/cours/toute-matiere/france/prepa',
  },
  {
    title: 'Prépa AL',
    url: '/cours/toute-matiere/france/prepa-al',
  },
  {
    title: 'Prépa BCPST',
    url: '/cours/toute-matiere/france/prepa-bcpst',
  },
  {
    title: 'Prépa BL',
    url: '/cours/toute-matiere/france/prepa-bl',
  },
  {
    title: 'Prépa ENS',
    url: '/cours/toute-matiere/france/prepa-ens',
  },
  {
    title: 'Prépa HEC',
    url: '/cours/toute-matiere/france/prepa-hec',
  },
  {
    title: 'Prépa Littéraire',
    url: '/cours/toute-matiere/france/prepa-litteraire',
  },
  {
    title: 'Prépa PSI',
    url: '/cours/toute-matiere/france/prepa-psi',
  },
  {
    title: 'Prépa Sciences Po',
    url: '/cours/toute-matiere/france/prepa-sciences-po',
  },
  {
    title: 'Primaire',
    url: '/cours/toute-matiere/france/primaire',
  },
  {
    title: 'Seconde',
    url: '/cours/toute-matiere/france/seconde',
  },
  {
    title: 'Terminale',
    url: '/cours/toute-matiere/france/terminale',
  },
];

export const CITIES_LIST = [
  {
    title: 'Paris',
    url: '/cours/toute-matiere/paris',
  },
  {
    title: 'Lyon',
    url: '/cours/toute-matiere/lyon',
  },
  {
    title: 'Lille',
    url: '/cours/toute-matiere/lille',
  },
  {
    title: 'Nantes',
    url: '/cours/toute-matiere/nantes',
  },
  {
    title: 'Montpellier',
    url: '/cours/toute-matiere/montpellier',
  },
  {
    title: 'Rennes',
    url: '/cours/toute-matiere/rennes',
  },
  {
    title: 'Grenoble',
    url: '/cours/toute-matiere/grenoble',
  },
  {
    title: 'Angers',
    url: '/cours/toute-matiere/angers',
  },
  {
    title: 'Toulouse',
    url: '/cours/toute-matiere/toulouse',
  },
  {
    title: 'Dijon',
    url: '/cours/toute-matiere/dijon',
  },
  {
    title: 'Caen',
    url: '/cours/toute-matiere/caen',
  },
  {
    title: 'Reims',
    url: '/cours/toute-matiere/reims',
  },
  {
    title: 'Strasbourg',
    url: '/cours/toute-matiere/strasbourg',
  },
  {
    title: 'Bordeaux',
    url: '/cours/toute-matiere/bordeaux',
  },
  {
    title: 'Chalon-sur-Saône',
    url: '/cours/toute-matiere/chalon-sur-saone',
  },
  {
    title: 'Pau',
    url: '/cours/toute-matiere/pau',
  },
  {
    title: 'Nice',
    url: '/cours/toute-matiere/nice',
  },
  {
    title: 'Marseille',
    url: '/cours/toute-matiere/marseille',
  },
  {
    title: 'Le Havre',
    url: '/cours/toute-matiere/le-havre',
  },
  {
    title: 'Colmar',
    url: '/cours/toute-matiere/colmar',
  },
];
