import { useEffect, useState } from 'react';

type Props = {
  y?: number;
  yPercent?: number;
};

export type UseHasScrollBeyond = (_props: Props) => {
  hasScrollBeyond: boolean;
};

export const useHasScrollBeyond: UseHasScrollBeyond = ({
  y,
  yPercent,
}: Props) => {
  const [hasScrollBeyond, setHasScrollBeyond] = useState(false);

  useEffect(() => {
    const targetY = y || window.innerHeight * yPercent;

    const handleScroll = () => {
      setHasScrollBeyond(window.scrollY > targetY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [y, yPercent]);

  return { hasScrollBeyond };
};
