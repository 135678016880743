import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing-mozza.landing-subjects.title',
    defaultMessage:
      '<underlined>Choisissez la matière</underlined> dans laquelle vous souhaitez<enter></enter>progresser',
  },
  subtitle: {
    id: 'landing-mozza.landing-subjects.subtitle',
    defaultMessage:
      'Nous vous aidons à trouver le professeur particulier idéal.',
  },
});
