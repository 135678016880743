import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ shadow, breakpoints }) => ({
  searchInput: {
    position: 'relative',
    flexGrow: 1,
    height: rem(58),
  },
  input: {
    height: '100%',
  },
  options: {
    zIndex: 100,
    position: 'absolute',
    top: rem(60),
    left: rem(12),
    right: rem(12),
    boxShadow: shadow.high,
    [breakpoints.up('md')]: {
      top: rem(50),
      left: rem(0),
      right: rem(0),
    },
  },
}));
