import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from '../messages';

import useStyles from './styles';

const LOGOS = [
  'tf1',
  'm6',
  'c8',
  'tmc',
  'bfm-tv',
  'lci',
  'w9',
  'nrj12',
  'les-echos',
  'etudiant',
  'le-parisien',
  'tfx',
  'forbes',
  'ab1',
  'france-bleu',
  'cherie25',
];

const SeenOnSafari = () => {
  const { classes } = useStyles();

  return (
    <section className={classes.container}>
      <Typography variant="titleSm" component="h2">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div className={classes.logos}>
        {LOGOS.map((logo, index) => {
          const src = `https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/logo-${logo}.png`;

          return (
            <Image
              key={index}
              className={classes.logo}
              src={src}
              alt={logo}
              width={136}
              height={60}
              loader={() =>
                getImageFromCloudImage({
                  quality: 10,
                  src,
                  width: 136,
                })
              }
            />
          );
        })}
      </div>
    </section>
  );
};

export default SeenOnSafari;
