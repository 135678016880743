import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import Icon from '@/designSystem/Icon/Icon';
import { useQueryImmutable } from '@/hooks/useQueryImmutable';
import Input from '@/Molecules/Input/Input';
import Options from '@/Organisms/SearchBar/DrawerOptions/DrawerOptions';
import { orderSubjects } from '@/Organisms/SearchBar/utils';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { defaultSearchSubjects } from '@/shared/defaultSearchSubjects';

import messages from './messages';

import useStyles from './styles';

type Props = {
  setSubjectQuery: Dispatch<SetStateAction<string>>;
};

const SubjectInput: FC<Props> = ({ setSubjectQuery }) => {
  const inputRef = useRef<HTMLDivElement>();

  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { isLoading, data: subjects } = useQueryImmutable(
    ['subject'],
    apiGetSubjects
  );
  const [subject, setSubject] = useState('');
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [drawerOptions, setDrawerOptions] = useState(defaultSearchSubjects);

  // autocomplete
  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef?.current && !inputRef?.current?.contains(event.target)) {
        setIsOptionOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  const getOptions = (inputValue) => {
    setIsOptionOpen(true);
    const dataOptions = orderSubjects(isLoading ? [] : subjects, inputValue);
    if (dataOptions?.length > 0) {
      setDrawerOptions(dataOptions);
    } else if (inputValue.length === 0) {
      setDrawerOptions(defaultSearchSubjects);
    } else {
      setDrawerOptions([]);
    }
  };

  const updateSubjectInput = (inputSubject: string) => {
    getOptions(inputSubject);
    setSubject(inputSubject);
  };
  const onSubjectOptionClick = (option: string) => {
    setSubject(option);
    setSubjectQuery(option);
    setIsOptionOpen(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const input = e.target.value;
      setSubject(input);
      setSubjectQuery(input);
      setIsOptionOpen(false);
    }
  };

  return (
    <div className={classes.searchInput} ref={inputRef}>
      <Input
        placeholder={formatMessage(messages.subject)}
        type="text"
        onKeyDown={onKeyDown}
        onChange={(e) => updateSubjectInput(e.target.value)}
        onFocus={(e) => updateSubjectInput(e.target.value)}
        value={subject}
        containerClassName={classes.input}
        autoComplete="off"
      >
        <Input.Adornment variant="start">
          <Icon icon="bookOpen" />
        </Input.Adornment>
      </Input>
      {isOptionOpen && (
        <div className={classes.options}>
          <Options options={drawerOptions} onClick={onSubjectOptionClick} />
        </div>
      )}
    </div>
  );
};

export default SubjectInput;
