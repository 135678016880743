import { FC, PropsWithChildren } from 'react';
import { FocusOn } from 'react-focus-on';

import LoaderAnimation from '@/Molecules/Loader/LoaderAnimation';

import useStyles from './styles';

const ModalLoader: FC<PropsWithChildren<unknown>> = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.backdrop}>
      <FocusOn scrollLock={false} focusLock={false}>
        <LoaderAnimation />
      </FocusOn>
    </div>
  );
};

export default ModalLoader;
