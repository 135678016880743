import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    display: 'inline-flex',
    gap: rem(8),
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    height: rem(56),
    borderRadius: rem(47),
    backgroundColor: palette.common.white,
    color: palette.neutral[200],
    border: 'none',
    boxShadow: `0px -1px 0px 0px ${palette.primary[700]} inset`,
    '&:hover': {
      backgroundColor: palette.primary.main,
      '> button > span': {
        color: palette.common.white,
      },
    },
  },
}));
