import { FC, PropsWithChildren } from 'react';

import useStyles from './styles';

const LoaderAnimation: FC<PropsWithChildren<unknown>> = () => {
  const { classes, cx } = useStyles();
  return (
    <div className={classes.loader}>
      <div className={cx(classes.smallGreen, classes.right)} />
      <div className={cx(classes.smallGreen, classes.left)} />
    </div>
  );
};

export default LoaderAnimation;
