import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(60),
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(28),
  },
  buttonContainer: {
    alignSelf: 'center',
  },
}));
