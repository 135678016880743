import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    pointerEvents: 'none',
    zIndex: 21,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: palette.supporting.green[600],
    opacity: 0,
    transform: 'translateY(100%)',
    transition: 'opacity 0.3s ease-out, transform 0.4s ease-out',
  },
  showBanner: {
    pointerEvents: 'auto',
    opacity: 1,
    transform: 'translateY(0)',
  },
  centerContainer: {
    margin: '0 auto',
    maxWidth: rem(1440),
    padding: `0 ${rem(16)}`,
    [breakpoints.up('md')]: {
      padding: `0 ${rem(80)}`,
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: rem(12),
    padding: `${rem(12)} 0`,
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: `${rem(16)}`,
      gap: rem(8),
    },
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: rem(8),
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    gap: rem(46),
    width: '100%',
    [breakpoints.up('lg')]: {
      width: 'unset',
    },
  },
  emoji: {
    lineHeight: 1,
  },
  description: {
    textAlign: 'center',
    [breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  closeButtonContainer: {
    position: 'absolute',
    top: rem(10),
    right: 0,
    [breakpoints.up('lg')]: {
      position: 'unset',
    },
  },
}));
