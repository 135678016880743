import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import Reason from '@/components/LandingMozza/common/Hero/Reason/Reason';
import SearchBar from '@/components/LandingMozza/common/Hero/SearchBar/SearchBar';

import messages from '../messages';

import useStyles from './styles';

interface Props {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  reasons?: string[] | ReactElement[];
}
const Hero: FC<Props> = ({ title, subtitle, reasons }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.header}>
          {title ? (
            <Typography component="h1" variant="title2Xl">
              {title}
            </Typography>
          ) : (
            <Typography component="span" variant="title2Xl">
              {title ?? <FormattedMessage {...messages.title} />}
            </Typography>
          )}
          <div>
            {subtitle ? (
              <Typography
                component="h2"
                variant="body2xl"
                className={classes.neutralText}
              >
                {subtitle}
              </Typography>
            ) : (
              <Typography
                component="h1"
                variant="body2xl"
                className={classes.neutralText}
              >
                <FormattedMessage {...messages.subtitle} />
              </Typography>
            )}
          </div>
        </div>
        {reasons?.length > 0 && (
          <ul className={classes.reasons}>
            {reasons.map((reason, index) => (
              <Reason key={`reason-${index}`}>
                <Typography component="span" className={classes.neutralText}>
                  {reason}
                </Typography>
              </Reason>
            ))}
          </ul>
        )}
        <SearchBar />
      </div>
    </div>
  );
};

export default Hero;
