import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const animation = (offsetX: string) => keyframes`
  from {
    transform: translate3d(0rem, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  to {
    transform: translate3d(${offsetX}, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`;

export default makeStyles<{ offsetX: string; speed?: number }>()(
  (_, { offsetX, speed = 10000 }) => ({
    container: {
      display: 'inline-flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      transformStyle: 'preserve-3d',
      willChange: 'transform',
      animation: `${animation(offsetX)} ${speed}ms infinite linear`,
    },
  })
);
