import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { TagSize, TagVariant } from '@/designSystem/Tag/types';

type Props = {
  size: TagSize;
  variant: TagVariant;
};

const getBorderRadius = (size: TagSize, variant: TagVariant) => {
  let borderRadius: string;

  if (variant === 'rounded') {
    return rem(100);
  }

  switch (size) {
    case 'small':
      borderRadius = rem(4);
      break;
    case 'large':
    case 'medium':
    default:
      borderRadius = rem(8);
      break;
  }
  return borderRadius;
};

const getPadding = (size: TagSize) => {
  let padding: string;

  switch (size) {
    case 'small':
      padding = `0 ${rem(4)}`;
      break;
    case 'large':
      padding = `${rem(8)}`;
      break;
    case 'medium':
    default:
      padding = `${rem(4)} ${rem(8)}`;
      break;
  }
  return padding;
};

export default makeStyles<Props>()((theme, { variant, size }) => ({
  container: {
    display: 'flex',
    padding: getPadding(size),
    alignItems: 'center',
    width: 'max-content',
    borderRadius: getBorderRadius(size, variant),
    gap: rem(6),
    backgroundColor: theme.palette.neutral[900],
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  label: {
    color: 'currentColor',
  },
}));
