import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { CSS_ELEMENT_TOP } from '@/hooks/animation/useAddContainerTopValueOnCssVar';

const animationSpeed = 0.2;

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    left: '50%',
    transform: 'rotate(-1deg) translateX(-50%)',
    width: '95vw',
    overflow: 'hidden',
    gap: rem(14),
  },
  subjects: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    listStyle: 'none',
    padding: 0,
    gap: rem(14),
    margin: 0,
  },
  subjectsOffset: {
    marginRight: rem(-320),
  },
  transformTop: {
    transform: `translateX(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed}))`,
  },
  transformBottom: {
    transform: `translateX(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed} * -1))`,
  },
}));
