import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: rem(124),
    borderRadius: rem(16),
    boxShadow: '0px -1px 0px 0px rgba(228, 229, 230, 1) inset',
    padding: rem(14),
    [breakpoints.up('md')]: {
      width: rem(320),
    },
    backgroundColor: palette.common.white,
  },
  teachersTotal: {
    whiteSpace: 'nowrap',
    color: palette.neutral['500'],
  },
  subject: {
    display: 'block',
  },
}));
