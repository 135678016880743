import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'landing-mozza.landing-subjects.btnDiscover',
    defaultMessage: 'Découvrir nos professeurs',
  },
  subjectInformatique: {
    id: 'landing-mozza.landing-subjects.subjectInformatique',
    defaultMessage: 'Informatique',
  },
  subjectHistoire: {
    id: 'landing-mozza.landing-subjects.subjectHistoire',
    defaultMessage: 'Histoire',
  },
  subjectFrancais: {
    id: 'landing-mozza.landing-subjects.subjectFrancais',
    defaultMessage: 'Français',
  },
  subjectMaths: {
    id: 'landing-mozza.landing-subjects.subjectMaths',
    defaultMessage: 'Maths',
  },
  subjectPhysique: {
    id: 'landing-mozza.landing-subjects.subjectPhysique',
    defaultMessage: 'Physique',
  },
  subjectSVT: {
    id: 'landing-mozza.landing-subjects.subjectSVT',
    defaultMessage: 'SVT',
  },
  subjectMethodologie: {
    id: 'landing-mozza.landing-subjects.subjectMethodologie',
    defaultMessage: 'Méthodologie',
  },
  subjectPhilosophie: {
    id: 'landing-mozza.landing-subjects.subjectPhilosophie',
    defaultMessage: 'Philosophie',
  },
  subjectSES: {
    id: 'landing-mozza.landing-subjects.subjectSES',
    defaultMessage: 'SES',
  },
  subjectAnglais: {
    id: 'landing-mozza.landing-subjects.subjectAnglais',
    defaultMessage: 'Anglais',
  },
  subjectEspagnol: {
    id: 'landing-mozza.landing-subjects.subjectEspagnol',
    defaultMessage: 'Espagnol',
  },
  subjectGeopolitique: {
    id: 'landing-mozza.landing-subjects.subjectGeopolitique',
    defaultMessage: 'Géopolitique',
  },
});
