import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ border, breakpoints, shadow }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(20),
  },
  searchAndTags: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: rem(16),
    border: border.main,
    boxShadow: shadow.high,
    width: '100%',
    maxWidth: rem(624),
    [breakpoints.up('sm')]: {
      alignSelf: 'center',
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: rem(12),
    padding: rem(8),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  searchInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    width: '100%',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  searchBtnContainer: {
    flexShrink: 0,
  },
}));
