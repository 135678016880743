import { defineMessages } from 'react-intl';

export default defineMessages({
  teacher: {
    id: 'landing-mozza.landing-subjects.card-row.teacher',
    defaultMessage: '{number} professeurs',
  },
  seeMore: {
    id: 'landing-mozza.landing-subjects.card-row.seeMore',
    defaultMessage: 'Voir',
  },
});
