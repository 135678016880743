import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  focuslock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  backdrop: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: rem(10),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    opacity: '1',
    transition: 'opacity 0.5s ease',
  },
}));
