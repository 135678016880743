const structuredHomeData = {
  '@context': 'https://schema.org/',
  '@graph': [
    {
      '@type': 'WebPage',
      '@id': 'https://sherpas.com/#webpage',
      url: 'https://sherpas.com/',
      headline:
        'Les Sherpas : Cours particuliers & Soutien scolaire sur mesure',
      name: 'Les Sherpas',
      description:
        'Trouvez le Prof particulier idéal ✓ Professeurs certifiés ✓ Excellence académique (HEC, Centrale, Sciences Po..) ✓ Collège, Lycée, Prépa, Fac ✓ Dès 15€/h',
      mentions: [
        {
          '@type': 'Organization',
          name: 'CentraleSupélec',
          sameAs: 'https://fr.wikipedia.org/wiki/CentraleSup%C3%A9lec',
        },
        {
          '@type': 'Organization',
          name: 'Centre universitaire Dauphine',
          sameAs: 'https://fr.wikipedia.org/wiki/Centre_universitaire_Dauphine',
        },
        {
          '@type': 'Organization',
          name: 'École normale supérieure Paris-Saclay',
          sameAs:
            'https://fr.wikipedia.org/wiki/%C3%89cole_normale_sup%C3%A9rieure_Paris-Saclay',
        },
        {
          '@type': 'Organization',
          name: 'ESSEC Business School',
          sameAs: 'https://fr.wikipedia.org/wiki/ESSEC_Business_School',
        },
        {
          '@type': 'Organization',
          name: 'HEC Paris',
          sameAs: 'https://fr.wikipedia.org/wiki/HEC_Paris',
        },
        {
          '@type': 'Organization',
          name: 'École nationale supérieure des mines de Paris',
          sameAs:
            'https://fr.wikipedia.org/wiki/%C3%89cole_nationale_sup%C3%A9rieure_des_mines_de_Paris',
        },
        {
          '@type': 'Organization',
          name: 'Sciences Po',
          sameAs: 'https://fr.wikipedia.org/wiki/Sciences_Po',
        },
        {
          '@type': 'Organization',
          name: 'École polytechnique',
          sameAs: 'https://fr.wikipedia.org/wiki/%C3%89cole_polytechnique',
        },
        {
          '@type': 'Thing',
          name: 'prix',
          sameAs: 'https://fr.wikipedia.org/wiki/Prix',
        },
        {
          '@type': 'Thing',
          name: 'élève',
          sameAs: 'https://fr.wikipedia.org/wiki/Étudiant',
        },
      ],
      about: [
        {
          '@type': 'Thing',
          name: 'Cours particuliers',
          sameAs: [
            'https://fr.wikipedia.org/wiki/Cours_particulier',
            'https://fr.wikipedia.org/wiki/Soutien_scolaire',
          ],
        },
        {
          '@type': 'LocalBusiness',
          '@id': 'https://sherpas.com/#LocalBusiness',
          url: 'https://sherpas.com/',
          name: 'Les Sherpas : Cours particuliers et Soutien scolaire',
          logo: 'https://sherpas.com/images/icons/SherpasDeskop.svg',
          image: 'https://sherpas.com/p/files/cours-particuliers.webp',
          description:
            'Trouvez le Prof particulier idéal ✓ Professeurs certifiés ✓ Excellence académique (HEC, Centrale, Sciences Po..) ✓ Collège, Lycée, Prépa, Fac ✓ Dès 15€/h',
          telephone: '+33176421408',
          priceRange: 'dès 15€/h',
          currenciesAccepted: 'EUR',
          paymentAccepted: 'Carte de crédit, virement, chèque',
          address: {
            '@type': 'PostalAddress',
            addressCountry: {
              '@type': 'Country',
              name: 'France',
            },
          },
          areaServed: {
            '@type': 'Place',
            name: 'France',
          },
        },
      ],
    },
    {
      '@type': 'EducationalOrganization',
      '@id': 'https://sherpas.com/#organization',
      name: 'Les Sherpas',
      url: 'https://sherpas.com/',
      brand: 'Les Sherpas',
      legalName: 'Les Sherpas',
      slogan: 'Plus qu’un professeur particulier, un Sherpa !',
      description:
        "Les Sherpas, c'est un service de cours particuliers mettant en relation élèves et parents avec des professeurs particuliers bienveillants et brillants : des Sherpas. Nos professeurs proposent des cours particuliers à domicile et/ou en ligne dans toutes les matières en primaire, au collège, au lycée, en prépa et dans le supérieur.",
      keywords:
        'cours particuliers, cours particulier, cours particuliers à domicile, cours particuliers en ligne, soutien scolaire, soutien scolaire à domicile, soutien scolaire en ligne, donner des cours particuliers, prof particulier, professeur particulier, aide aux devoirs',
      logo: 'https://sherpas.com/images/icons/SherpasDeskop.svg',
      image: 'https://sherpas.com/p/files/cours-particuliers.webp',
      sameAs: [
        'https://www.facebook.com/lessherpas/',
        'https://www.instagram.com/les_sherpas/',
        'https://www.youtube.com/c/LesSherpas',
        'https://www.tiktok.com/@lessherpas_parents',
        'https://fr.linkedin.com/company/les-sherpas',
        'https://twitter.com/_LesSherpas',
        'https://www.pinterest.fr/LesSherpasfr/',
        'https://www.dailymotion.com/LesSherpas',
        'https://www.welcometothejungle.com/fr/companies/les-sherpas',
        'https://www.glassdoor.fr/Avis/Les-Sherpas-Avis-E3268253.htm',
      ],
      openingHours: ['Mo-Fr 09:00-18:30', 'Sa 10:00-17:00'],
      areaServed: {
        '@type': 'Place',
        name: 'France',
      },
      email: 'support@sherpas.com',
      hasOfferCatalog: {
        '@type': 'OfferCatalog',
        name: 'Cours particuliers',
        itemListElement: [
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Maths',
              url: 'https://sherpas.com/cours/maths',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Math%C3%A9matiques',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Anglais',
              url: 'https://sherpas.com/cours/anglais',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Anglais',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Français',
              url: 'https://sherpas.com/cours/francais',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Fran%C3%A7ais',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Orthographe',
              url: 'https://sherpas.com/cours/orthographe',
              sameAs: [
                'hhttps://fr.wikipedia.org/wiki/Orthographe',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Physique',
              url: 'https://sherpas.com/cours/physique',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Physique',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Philosophie',
              url: 'https://sherpas.com/cours/philosophie',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Philosophie',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers SES',
              url: 'https://sherpas.com/cours/ses',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Sciences_%C3%A9conomiques_et_sociales',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Informatique',
              url: 'https://sherpas.com/cours/informatique',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Informatique',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers SVT',
              url: 'https://sherpas.com/cours/svt',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Sciences_de_la_vie_et_de_la_Terre',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Histoire',
              url: 'https://sherpas.com/cours/histoire',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Histoire',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Espagnol',
              url: 'https://sherpas.com/cours/espagnol',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Espagnol',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Italien',
              url: 'https://sherpas.com/cours/italien',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Italien',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Droit',
              url: 'https://sherpas.com/cours/droit',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Droit',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Biologie',
              url: 'https://sherpas.com/cours/biologie',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Biologie',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Chimie',
              url: 'https://sherpas.com/cours/chimie',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Chimie',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Médecine',
              url: 'https://sherpas.com/cours/medecine',
              sameAs: [
                'https://fr.wikipedia.org/wiki/M%C3%A9decine',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Allemand',
              url: 'https://sherpas.com/cours/allemand',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Allemand',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Langues',
              url: 'https://sherpas.com/cours/langues',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Langue',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Géopolitique',
              url: 'https://sherpas.com/cours/geopolitique',
              sameAs: [
                'https://fr.wikipedia.org/wiki/G%C3%A9opolitique',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Économie',
              url: 'https://sherpas.com/cours/economie',
              sameAs: [
                'https://fr.wikipedia.org/wiki/%C3%89conomie',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Cours particuliers Méthodologie',
              url: 'https://sherpas.com/cours/methodologie',
              sameAs: [
                'https://fr.wikipedia.org/wiki/M%C3%A9thodologie',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Aide aux devoirs',
              url: 'https://sherpas.com/cours/aide-aux-devoirs',
              sameAs: [
                'https://fr.wikipedia.org/wiki/Devoir_scolaire',
                'https://fr.wikipedia.org/wiki/Cours%20particulier',
              ],
            },
          },
          {
            '@type': 'Offer',
            itemOffered: {
              '@type': 'Service',
              name: 'Soutien scolaire',
              url: 'https://sherpas.com/cours/soutien-scolaire',
              sameAs: 'https://fr.wikipedia.org/wiki/Soutien%20scolaire',
            },
          },
        ],
      },
      address: {
        '@type': 'PostalAddress',
        streetAddress: '6 rue Général Clergerie',
        addressLocality: 'Paris',
        addressRegion: 'Île-de-France',
        postalCode: '75116',
        addressCountry: {
          '@type': 'Country',
          name: 'France',
        },
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '48.8624057',
        longitude: '2.2867879',
      },
      telephone: '+33176421408',
      aggregateRating: {
        '@type': 'AggregateRating',
        '@id': 'https://sherpas.com/#AggregateRating',
        ratingValue: '4.8',
        bestRating: '5',
        worstRating: '1',
        ratingCount: '120',
      },
      review: [
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Claire',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            'Une très bonne plateforme de mise en relation avec les élèves et Les Sherpas, on trouve pleins de matières différentes, la plateforme est intuitive et on est très bien accompagnés. je recommande !',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Baptiste',
            description:
              'Ancien élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            "Excellente plateforme ! Les professeurs répondent rapidement, et on y trouve des cours de qualité à des tarifs abordables. De plus, le site est bien conçu et clair. Il y a un très bon suivi de nos progrès : les membres de l'équipe de la plateforme prennent fréquemment de nos nouvelles et se montrent très disponibles. Cela s'avère donc pratique en cas de questions ou de demandes. Personnellement, lors de ma préparation aux concours, j'ai eu besoin de cours de français tout au long de l'année, et j'ai le sentiment d'avoir énormément progressé. Je recommande !",
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Corine',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            "Une très bonne plate-forme je ne peux que laisser un avis positif, l'interface est intuitive et conviviale et permet aux parents de naviguer facilement et de trouver des tuteurs qui conviennent. Le suivi est organisé et le service à la clientèle exceptionnel. Tout au long de mes interactions avec eux j'ai trouvé le personnel incroyablement compréhensif et sincèrement intéressé par les besoins de mon enfant. Merci Les Sherpas.",
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Kahina',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            "La plateforme Sherpas est très efficace pour mettre en lien étudiants / enseignants. Les outils de visio sont performants et le suivi réalisé par l'équipe est très rigoureux.",
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Catherine',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            "Je recommande tout à fait la plateforme des Sherpas. L'équipe est très réactive et les professeurs sont sélectionnés pour leur qualité.",
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Laureline',
            description:
              "Parent d'une élève ayant bénéficié des cours particuliers chez Les Sherpas.",
          },
          reviewBody:
            'Des profs super. Une équipe pédagogique disponible et à l écoute. Des cours modulables en fonction des besoins. Ma fille a progressé pour terminer le troisième trimestre avec une très bonne moyenne dans la.matière concernée. Je conseille vivement les sherpa.',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Clotilde',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            "Très satisfaite des sherpas ! Ils sont très réactifs et j'ai très rapidement trouvé les profs qui me convenaient. J'ai progressé très vite et ai donc pu ensuite consacrer les cours à de l'approfondissement. Je ne peux que recommander, super expérience.",
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Cristale',
            description:
              'Ancienne élève ayant bénéficié de soutien scolaire en maths chez Les Sherpas.',
          },
          reviewBody:
            'Très bonne expérience, les cours de maths ont été très pertinent, je suis très contente de mon suivi je recommande vivement !! Grâce à vous j’ai beaucoup progressé en maths!',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Lidia',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers de préparation au Bac chez Les Sherpas.',
          },
          reviewBody:
            'J’ai pris des cours particuliers pour me préparer au Bac et cela m’a beaucoup aidé. Je vous conseille ce site :)',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
        {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: 'Raphaëlle',
            description:
              'Ancienne élève ayant bénéficié des cours particuliers chez Les Sherpas.',
          },
          reviewBody:
            'Incroyable équipe qui est toujours présente aux côtés des élèves et qui met en relation les élèves avec des profs ultra compétents ! Merci pour tout',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: '5',
            bestRating: '5',
          },
        },
      ],
      potentialAction: {
        '@type': 'RegisterAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: 'https://sherpas.com/inscription',
          inLanguage: 'fr',
          actionPlatform: [
            'https://schema.org/DesktopWebPlatform',
            'https://schema.org/IOSPlatform',
            'https://schema.org/AndroidPlatform',
          ],
        },
        result: {
          '@type': 'EducationEvent',
          name: 'Inscription en ligne sur la plateforme Les Sherpas',
          startDate: '2023-11-01T09:00:00+01:00',
          eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
          location: {
            '@type': 'VirtualLocation',
            url: 'https://sherpas.com/inscription',
          },
        },
      },
      knowsAbout: [
        'https://fr.wikipedia.org/wiki/Cours%20particulier',
        'https://www.wikidata.org/wiki/Q16544755',
        'https://fr.wikipedia.org/wiki/Soutien%20scolaire',
        'https://www.wikidata.org/wiki/Q3492357',
        'https://fr.wikipedia.org/wiki/Professeur',
        'https://www.wikidata.org/wiki/Q22908942',
        'https://fr.wikipedia.org/wiki/R%C3%A9p%C3%A9titeur_(p%C3%A9dagogie)',
        'https://fr.wikipedia.org/wiki/Professeur',
        'https://fr.wikipedia.org/wiki/Baccalaur%C3%A9at%20(scolaire)',
        'https://fr.wikipedia.org/wiki/%C3%89cole',
        'https://fr.wikipedia.org/wiki/Coll%C3%A8ge',
        'https://fr.wikipedia.org/wiki/Lyc%C3%A9e',
        'https://fr.wikipedia.org/wiki/Lyc%C3%A9e_en_France',
        'https://fr.wikipedia.org/wiki/Classe%20pr%C3%A9paratoire%20aux%20grandes%20%C3%A9coles',
        'https://fr.wikipedia.org/wiki/Universit%C3%A9',
        'https://fr.wikipedia.org/wiki/%C3%89tudes%20sup%C3%A9rieures',
        'https://fr.wikipedia.org/wiki/Math%C3%A9matiques',
        'https://fr.wikipedia.org/wiki/Fran%C3%A7ais',
        'https://fr.wikipedia.org/wiki/Anglais',
        'https://fr.wikipedia.org/wiki/Dipl%C3%B4me%20national%20du%20brevet',
        'https://fr.wikipedia.org/wiki/Baccalaur%C3%A9at%20en%20France',
        'https://www.wikidata.org/wiki/Q7922',
        'https://www.wikidata.org/wiki/Q2822773',
        'https://www.wikidata.org/wiki/Q8434',
      ],
      founder: [
        {
          '@type': 'Person',
          name: 'Etienne Porche',
          description: 'Description du premier fondateur',
          url: 'https://sherpas.com/blog/author/etienne/',
          sameAs: ['https://fr.linkedin.com/in/etienne-porche'],
          '@id': 'https://sherpas.com/blog/author/etienne/#Person',
        },
        {
          '@type': 'Person',
          name: 'William Mievre',
          description: 'Co-fondateur de la plateforme Les Sherpas',
          url: 'https://sherpas.com/blog/author/william/',
          sameAs: ['https://fr.linkedin.com/in/william-mievre/fr'],
          '@id': 'https://sherpas.com/blog/author/william/#Person2',
        },
      ],
    },
  ],
};

export default structuredHomeData;
