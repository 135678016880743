import { FC } from 'react';
import { useIntl } from 'react-intl';

import Card from '@/components/LandingMozza/LandingMozza/Subjects/Card/Card';
import {
  cardRowBottom,
  cardRowTop,
} from '@/components/LandingMozza/LandingMozza/Subjects/config';
import useAddContainerTopValueOnCssVar from '@/hooks/animation/useAddContainerTopValueOnCssVar';

import useStyles from './styles';

type Props = {
  handleClickSubject: (_subject: string) => void;
};

const DesktopSubjects: FC<Props> = ({ handleClickSubject }) => {
  const { classes, cx } = useStyles();
  const { formatMessage } = useIntl();
  const { ref } = useAddContainerTopValueOnCssVar({
    elementMargins: { top: 20, bottom: 20 },
  });

  return (
    <div className={classes.container} ref={ref}>
      <ul className={cx(classes.subjects, classes.transformTop)}>
        {cardRowTop(formatMessage).map((cards, i) => (
          <li key={i}>
            <Card {...cards} handleClickSubject={handleClickSubject} />
          </li>
        ))}
      </ul>
      <ul
        className={cx(
          classes.subjects,
          classes.subjectsOffset,
          classes.transformBottom
        )}
      >
        {cardRowBottom(formatMessage).map((cards, i) => (
          <li key={i}>
            <Card {...cards} handleClickSubject={handleClickSubject} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DesktopSubjects;
