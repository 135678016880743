import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { locationOfCourse } from '@les-sherpas/sherpas-toolbox';
import { Divider } from '@mui/material';

import AddressInput from '@/components/LandingMozza/common/Hero/SearchBar/AddressInput/AddressInput';
import FamousTags from '@/components/LandingMozza/common/Hero/SearchBar/FamousTags/FamousTags';
import useStyles from '@/components/LandingMozza/common/Hero/SearchBar/styles';
import SubjectInput from '@/components/LandingMozza/common/Hero/SearchBar/SubjectInput/SubjectInput';
import RatingGoogle from '@/components/LandingMozza/common/RatingGoogle/RatingGoogle';
import Button from '@/designSystem/Button/Button';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

import messages from './messages';

const SearchBar: FC = () => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { pushTo, urls } = useNavigation();

  const [subject, setSubject] = useState('');

  const [address, setAddress] = useState<string>('');

  // search
  const onSearchClick = useCallback(() => {
    const params: { subject?: string; address?: string } = {};
    if (subject) {
      params.subject = subject;
    }

    if (address) {
      params.address = address;
    }
    pushTo(
      urls.search(
        params.subject || params.address
          ? {
              address: params.address,
              subject: params.subject,
              courseLocation: params.address
                ? undefined
                : locationOfCourse.online,
            }
          : undefined
      )
    );

    handleSendEventGTM('searchTeacher_heroLanding', { params });
  }, [address, pushTo, subject, urls]);

  return (
    <div className={classes.container}>
      <div className={classes.searchAndTags}>
        <div className={classes.search}>
          <div className={classes.searchInputs}>
            <SubjectInput setSubjectQuery={setSubject} />

            <AddressInput setAddressQuery={setAddress} />
          </div>
          <div className={classes.searchBtnContainer}>
            <Button
              variant="primary"
              size="large"
              isFullWidth
              onClick={onSearchClick}
              label={formatMessage(messages.find)}
            />
          </div>
        </div>
        <Divider orientation="horizontal" flexItem />

        <FamousTags />
      </div>
      <RatingGoogle gtmEvent="'gmb_heroLanding'" />
    </div>
  );
};

export default SearchBar;
