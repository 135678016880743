import * as React from 'react';
import { Libraries, Loader } from '@googlemaps/js-api-loader';

export type GoogleApiLibraries = Libraries;

type Type = {
  apiKey: string;
  channel?: string;
  client?: string;
  id: string;
  libraries: GoogleApiLibraries;
  language?: string;
  region?: string;
  version?: string;
  mapIds?: string[];
  nonce?: string;
  retries?: number;
  url?: string;
};

export const useGoogleApi = ({
  apiKey,
  channel,
  client,
  id,
  libraries,
  language,
  region,
  version,
  mapIds,
  nonce,
  retries,
  url,
}: Type) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [loadError, setLoadError] = React.useState<Error | undefined>(
    undefined
  );

  const loader = React.useMemo(
    () =>
      new Loader({
        apiKey,
        channel,
        client,
        id,
        libraries,
        language,
        region,
        version,
        mapIds,
        nonce,
        retries,
        url,
      }),
    [
      apiKey,
      channel,
      client,
      id,
      libraries,
      language,
      region,
      version,
      mapIds,
      nonce,
      retries,
      url,
    ]
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !isLoaded) {
      if (!document.querySelector('#google-maps-id')) {
        loader
          .load()
          .then(() => {
            setIsLoaded(true);
          })
          .catch((error) => {
            setLoadError(error);
          });
      }
    }
  }, [isLoaded, loader]);

  const prevLibraries = React.useRef<undefined | Libraries>();

  React.useEffect(() => {
    if (prevLibraries.current && libraries !== prevLibraries.current) {
      console.warn(
        'Performance warning! LoadScript has been reloaded unintentionally! You should not pass `libraries` prop as new array. Please keep an array of libraries as static class property for Components and PureComponents, or just a const variable outside of component, or somewhere in config files or ENV variables'
      );
    }
    prevLibraries.current = libraries;
  }, [libraries]);

  return { isLoaded, loadError };
};
