import { useEffect, useState } from 'react';
import {
  AnnounceApi,
  TeacherApi,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { AuthenticationType } from '@/components/Authentication/Authentication.utils';
import getOnboardingRedirectionUrl from '@/components/Authentication/OnboardingRedirection.utils';
import { GET_MILESTONE_STATUS_URL } from '@/shared/API/apiGetMilestoneStatus';

const useOnboardingRedirection = ({
  authentication,
  origin,
}: {
  authentication: AuthenticationType;
  origin?: string;
}) => {
  const { push, query, pathname } = useRouter();
  const [isOnboardingRedirectionEnd, setIsOnboardingRedirectionEnd] =
    useState(false);
  const isTeacher = authentication?.authData?.userType === UserType.Teacher;
  const isMilestoneStatusEnabled = isTeacher;
  const { data: milestonesStatus, isLoading: isLoadingMilestonesStatus } =
    useQuery<TeacherApi['private']['getMilestonesStatus']['response']>(
      [GET_MILESTONE_STATUS_URL],
      {
        enabled: isMilestoneStatusEnabled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );
  const isTeacherAnnouncesEnabled =
    isTeacher &&
    milestonesStatus &&
    milestonesStatus.profileCompletionPercentage < 100;
  const { data: teacherAnnounces, isLoading: isLoadingTeacherAnnounces } =
    useQuery<AnnounceApi['private']['getTeacherAnnounces']['response']>(
      ['announce/private/get-teacher-announces'],
      {
        enabled: isTeacherAnnouncesEnabled,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

  useEffect(() => {
    if (
      (isMilestoneStatusEnabled && isLoadingMilestonesStatus) ||
      (isTeacherAnnouncesEnabled && isLoadingTeacherAnnounces)
    )
      return;

    const { authData } = authentication || {};
    if (authData) {
      const redirectionUrl = getOnboardingRedirectionUrl({
        authentication,
        pathname,
        query,
        milestonesStatus,
        teacherAnnounces,
      });
      const redirectionWithoutParams = redirectionUrl?.split('?')[0];
      if (redirectionUrl && origin !== redirectionWithoutParams) {
        push(redirectionUrl);
      } else {
        setIsOnboardingRedirectionEnd(true);
      }
    } else {
      setIsOnboardingRedirectionEnd(!authData);
    }
  }, [
    pathname,
    authentication,
    isLoadingMilestonesStatus,
    isLoadingTeacherAnnounces,
    isMilestoneStatusEnabled,
    isTeacherAnnouncesEnabled,
    milestonesStatus,
    origin,
    push,
    query,
    teacherAnnounces,
  ]);

  return { isOnboardingRedirectionEnd };
};

export default useOnboardingRedirection;
