import { Children, FC, PropsWithChildren, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

import useStyles from './styles';

type Props = {
  className?: string;
  /** in milliseconds */
  speed?: number;
};

const InfiniteCarousel: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  speed,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const firstChildRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth = 0 } = useResizeObserver<HTMLDivElement>({
    ref: containerRef,
  });
  const { width: firstChildWidth = 0 } = useResizeObserver<HTMLDivElement>({
    ref: firstChildRef,
  });

  const { classes, cx } = useStyles({
    offsetX: `-${firstChildWidth}px`,
    speed,
  });

  const nbOtherChildren = Math.ceil(
    firstChildWidth ? containerWidth / firstChildWidth : 0
  );

  if (Children.count(children) !== 1)
    throw new Error('InfiniteCarousel must have exactly one child');

  return (
    <div className={cx(classes.container, className)} ref={containerRef}>
      <div className={classes.content}>
        {Children.map(children, (child) => (
          <div ref={firstChildRef}>{child}</div>
        ))}
        {new Array(nbOtherChildren).fill(0).map((_, index) => (
          <div key={index}>{children}</div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteCarousel;
