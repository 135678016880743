import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import Slider from 'react-slick';

import Card from '@/components/LandingMozza/LandingMozza/Subjects/Card/Card';
import subjects from '@/components/LandingMozza/LandingMozza/Subjects/config';
import TutorialStepper from '@/Organisms/Tutorial/TutorialStepper/TutorialStepper';

import useStyles from './styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
  handleClickSubject: (_subject: string) => void;
};

const MobileSubjects: FC<Props> = ({ handleClickSubject }) => {
  const { classes } = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const { formatMessage } = useIntl();

  return (
    <>
      <Slider afterChange={(index) => setStepIndex(index)} infinite={false}>
        {subjects.map((subject, i) => (
          <div key={`subject-page-${i}`}>
            <div className={classes.page}>
              {subject(formatMessage).map((card, i) => (
                <div key={`subject-card-${i}`}>
                  <Card {...card} handleClickSubject={handleClickSubject} />
                </div>
              ))}
              <div />
            </div>
          </div>
        ))}
      </Slider>
      <div className={classes.containerStepper}>
        <TutorialStepper>
          <TutorialStepper.Step
            isActive={stepIndex === 0}
            arial-label="tab_step_0"
          />
          <TutorialStepper.Step
            isActive={stepIndex === 1}
            arial-label="tab_step_1"
          />
        </TutorialStepper>
      </div>
    </>
  );
};

export default MobileSubjects;
