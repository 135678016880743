import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLevel: {
    id: 'landingMozza.SeoLinksLandingMozza.titleLevel',
    defaultMessage: 'Niveaux scolaires assurés',
  },
  titleSubject: {
    id: 'landingMozza.SeoLinksLandingMozza.titleSubject',
    defaultMessage: 'Matières enseignées',
  },
  titleCities: {
    id: 'landingMozza.SeoLinksLandingMozza.titleCities',
    defaultMessage: 'Villes de nos profs particuliers',
  },
});
