import { FC, useCallback, useEffect, useState } from 'react';
import {
  MarketingMessageApi,
  MarketingMessageTarget,
} from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import Button from '@/designSystem/Button/Button';
import {
  UseHasScrollBeyond,
  useHasScrollBeyond as useHasScrollBeyondDI,
} from '@/hooks/animation/useHasScrollBeyond';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';

import useStyles from './styles';

type Props = { useHasScrollBeyond?: UseHasScrollBeyond };

const MarketingBanner: FC<Props> = ({
  useHasScrollBeyond = useHasScrollBeyondDI,
}) => {
  const { classes, cx } = useStyles();
  const { pathname } = useRouter();
  const { pushToUnsafe } = useNavigation();
  const { hasScrollBeyond } = useHasScrollBeyond({ yPercent: 0.4 });
  const { data: marketingMessage } = useQuery<
    MarketingMessageApi['public']['getFirst']['response']
  >([`marketing-message/public`]);
  const [isVisible, setIsVisible] = useState(false);

  const sendMarketingMessageEvent = useCallback(
    (eventName: string) => {
      handleSendEventGTM(`marketing-banner-${eventName}`, {
        pathname,
        marketingMessage,
      });
    },
    [marketingMessage, pathname]
  );

  useEffect(() => {
    setIsVisible(marketingMessage?.isActive ?? false);
  }, [marketingMessage]);

  useEffect(() => {
    if (hasScrollBeyond) {
      sendMarketingMessageEvent('show');
    }
  }, [hasScrollBeyond, sendMarketingMessageEvent]);

  const handleClose = () => {
    setIsVisible(false);
    sendMarketingMessageEvent('close');
  };

  const handleSubmit = () => {
    const { redirectUrl, target } = marketingMessage;
    sendMarketingMessageEvent('submit');
    if (target === MarketingMessageTarget.blank) {
      window.open(redirectUrl, target);
    } else {
      pushToUnsafe(redirectUrl);
    }
  };

  return (
    isVisible && (
      <div
        className={cx(classes.container, hasScrollBeyond && classes.showBanner)}
      >
        <div className={classes.centerContainer}>
          <div className={classes.content}>
            <div className={classes.left}>
              <Typography
                variant="titleLg"
                component="span"
                className={classes.emoji}
              >
                {marketingMessage.emoji}
              </Typography>
              <Typography
                variant="title2Xs"
                component="span"
                className={classes.description}
              >
                {marketingMessage.description}
              </Typography>
            </div>
            <div className={classes.right}>
              <Button
                size="large"
                isFullWidth
                onClick={handleSubmit}
                label={marketingMessage.actionLabel}
              />
              <div className={classes.closeButtonContainer}>
                <Button
                  variant="tertiary"
                  size="large"
                  isFullWidth
                  onClick={handleClose}
                  icon="x"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MarketingBanner;
