import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

const useStepStyle = makeStyles<Step>()(({ palette }, { isActive }) => ({
  step: {
    width: isActive ? rem(24) : rem(8),
    height: rem(8),
    /** comes from design */
    borderRadius: rem(1000),
    backgroundColor: isActive ? palette.neutral[300] : palette.neutral[800],
  },
}));

type Step = {
  isActive?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const TutorialStep: FC<PropsWithChildren<Step>> = ({
  isActive = false,
  ...rest
}) => {
  const { classes } = useStepStyle({ isActive });
  return <div className={classes.step} {...rest} />;
};

type Compound = {
  Step: FC<PropsWithChildren<Step>>;
};

const useStepperStyle = makeStyles()(() => ({
  stepper: {
    display: 'flex',
    gap: rem(8),
  },
}));

const TutorialStepper: FC<PropsWithChildren<unknown>> & Compound = ({
  children,
}) => {
  const { classes } = useStepperStyle();
  return <div className={classes.stepper}>{children}</div>;
};

TutorialStepper.Step = TutorialStep;

export default TutorialStepper;
