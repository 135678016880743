/* eslint-disable no-undef */
// issue with eslint and type google which consider undefined because the real type is google.maps
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAddress, wait } from '@les-sherpas/sherpas-toolbox';

/* eslint-disable camelcase */
const geocoderService = { current: null };

const getPropertyName = (
  addressComponent: google.maps.GeocoderAddressComponent[],
  typeOfComponent: string
): string =>
  addressComponent.find(
    ({ types }) => !!types.find((type) => type === typeOfComponent)
  )?.long_name;

const getGeoCoderResult = async ({
  address,
  placeId,
}: {
  address: string;
  placeId?: string;
}): Promise<google.maps.GeocoderResult[]> => {
  const { results } = await (geocoderService.current as any).geocode(
    placeId ? { placeId } : { address }
  );
  return results as google.maps.GeocoderResult[];
};

const googleGeocoderService = async (
  address: string,
  placeId?: string,
  retry = true
): Promise<IAddress> => {
  if (
    !geocoderService.current &&
    (window as any).google &&
    (window as any).google.maps &&
    (window as any).google.maps.Geocoder
  ) {
    geocoderService.current = new (window as any).google.maps.Geocoder();
  }

  if (!geocoderService.current) {
    // parfois, geocoderService.current n'est pas défini car window.google n'est pas encore chargé, un délai devrait aider
    if (retry) {
      await wait(0.2);
      return googleGeocoderService(address, placeId, false);
    }
    return undefined;
  }

  if (placeId || address) {
    const geocoderResult = await getGeoCoderResult({ placeId, address });
    if (geocoderResult && geocoderResult[0]) {
      const {
        geometry,
        address_components,
        formatted_address,
      }: google.maps.GeocoderResult = geocoderResult[0];
      const latLng = geometry.location;
      const latitude = latLng.lat();
      const longitude = latLng.lng();
      const formattedAddress = formatted_address;
      const streetNumber = getPropertyName(address_components, 'street_number');
      const streetName = getPropertyName(address_components, 'route');
      const city = getPropertyName(address_components, 'locality');
      const zipCode = getPropertyName(address_components, 'postal_code');

      return {
        streetNumber,
        streetName,
        city,
        zipCode,
        latitude,
        longitude,
        formattedAddress,
      };
    }
  }
  return null;
};

export default googleGeocoderService;
