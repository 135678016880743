import { ComponentProps, FC, ReactElement } from 'react';
import { Typography } from '@mui/material';

import Icon from '@/designSystem/Icon/Icon';
import { TagSize, TagVariant } from '@/designSystem/TagButton/types';

import useStyles from './styles';

export type TagButtonProps = {
  size: TagSize;
  label: ReactElement | string;
  onClick: () => void;
  className?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  iconColor?: ComponentProps<typeof Icon>['color'];
  variant?: TagVariant;
};

const getTypographyVariant = (size: TagSize) => {
  let typographyVariant: 'bodySmBold' | 'bodyMdBold';

  switch (size) {
    case 'large':
      typographyVariant = 'bodyMdBold';
      break;
    case 'small':
    case 'medium':
    default:
      typographyVariant = 'bodySmBold';
      break;
  }

  return typographyVariant;
};

const getIconSize = (size: TagSize) => {
  let iconSize: ComponentProps<typeof Icon>['size'];

  switch (size) {
    case 'large':
      iconSize = 'large';
      break;
    case 'small':
    case 'medium':
    default:
      iconSize = 'medium';
      break;
  }
  return iconSize;
};

const TagButton: FC<TagButtonProps> = ({
  size,
  label,
  onClick,
  className = '',
  variant = 'default',
  icon = undefined,
  iconColor = 'neutral',
}) => {
  const { classes, cx } = useStyles({
    variant,
    size,
  });

  return (
    <button
      type="button"
      className={cx(classes.container, className)}
      onClick={onClick}
    >
      {!!icon && (
        <Icon icon={icon} size={getIconSize(size)} color={iconColor} />
      )}
      <Typography
        className={classes.label}
        component="span"
        variant={getTypographyVariant(size)}
      >
        {label}
      </Typography>
    </button>
  );
};

export default TagButton;
