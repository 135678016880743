import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${rem(40)} 0`,
    textAlign: 'center',
    gap: rem(12),
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(28),
  },
  logo: {
    objectFit: 'contain',
  },
}));
