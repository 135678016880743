import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import Button from '@/designSystem/Button/Button';

import messages from './messages';

import useStyles from './styles';

type Props = {
  subject: string;
  icon: string;
  teachersTotal: number;
  handleClickSubject: (_subject: string) => void;
};

const Card: FC<Props> = ({
  subject,
  icon,
  teachersTotal,
  handleClickSubject,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      {icon}
      <Typography variant="bodyLgBold" className={classes.subject}>
        {subject}
      </Typography>
      <Typography
        component="p"
        variant="bodyMd"
        className={classes.teachersTotal}
      >
        <FormattedMessage
          {...messages.teacher}
          values={{ number: teachersTotal }}
        />
      </Typography>
      <Button
        variant="link"
        onClick={() => handleClickSubject(subject)}
        label={formatMessage(messages.seeMore)}
      />
    </div>
  );
};

export default Card;
