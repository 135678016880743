import { defineMessages } from 'react-intl';

export default defineMessages({
  college: {
    id: 'landing-mozza.landing-subjects.college',
    defaultMessage: 'Collège',
  },
  lycee: {
    id: 'landing-mozza.landing-subjects.lycee',
    defaultMessage: 'Lycée',
  },
  prepa: {
    id: 'landing-mozza.landing-subjects.prepa',
    defaultMessage: 'Prépa',
  },
  superieur: {
    id: 'landing-mozza.landing-subjects.superieur',
    defaultMessage: 'Études supérieures',
  },
});
