// Note: this contain only part of the data used by LandingSubjects

import messages from '@/components/LandingMozza/LandingMozza/Subjects/messages';

// todo: Default export... everywhere, required ?
export const cardRowTop = (formatMessage) => [
  {
    subject: formatMessage(messages.subjectInformatique),
    icon: '💻',
    teachersTotal: 135,
  },
  {
    subject: formatMessage(messages.subjectHistoire),
    icon: '📜',
    teachersTotal: 177,
  },
  {
    subject: formatMessage(messages.subjectFrancais),
    icon: '🇫🇷',
    teachersTotal: 482,
  },
  {
    subject: formatMessage(messages.subjectMaths),
    icon: '📏',
    teachersTotal: 1721,
  },
  {
    subject: formatMessage(messages.subjectPhysique),
    icon: '🧪',
    teachersTotal: 436,
  },
  {
    subject: formatMessage(messages.subjectSVT),
    icon: '🔬',
    teachersTotal: 159,
  },
];

export const cardRowBottom = (formatMessage) => [
  {
    subject: formatMessage(messages.subjectMethodologie),
    icon: '🏫',
    teachersTotal: 192,
  },
  {
    subject: formatMessage(messages.subjectPhilosophie),
    icon: '🌌',
    teachersTotal: 115,
  },
  {
    subject: formatMessage(messages.subjectSES),
    icon: '📈',
    teachersTotal: 176,
  },
  {
    subject: formatMessage(messages.subjectAnglais),
    icon: '🇬🇧',
    teachersTotal: 619,
  },
  {
    subject: formatMessage(messages.subjectEspagnol),
    icon: '🇪🇸',
    teachersTotal: 172,
  },
  {
    subject: formatMessage(messages.subjectGeopolitique),
    icon: '🌍',
    teachersTotal: 67,
  },
];

const subjects = [cardRowTop, cardRowBottom];

export default subjects;
