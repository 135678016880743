import rem from 'polished/lib/helpers/rem';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const spin = keyframes`
  from, 0%, to {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export default makeStyles()(({ palette }) => ({
  loader: {
    position: 'relative',
    width: rem(120),
    height: rem(120),
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `${rem(30)} solid ${palette.neutral[800]}`,
    borderTop: `${rem(30)} solid ${palette.primary[400]}`,
    animation: `${spin} 1s ease-in-out infinite`,
  },
  smallGreen: {
    zIndex: 1,
    position: 'absolute',
    width: rem(30),
    height: rem(30),
    backgroundColor: palette.primary[400],
    borderRadius: '100%',
  },
  left: {
    top: rem(-18),
    left: rem(-16),
  },
  right: {
    top: rem(-18),
    right: rem(-16),
  },
}));
