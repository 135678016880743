import { FC } from 'react';
import { useIntl } from 'react-intl';
import { EducationalStage } from '@les-sherpas/sherpas-toolbox';

import Cotillon from '@/components/LandingMozza/common/Cotillon/Cotillon';
import DesktopSubjects from '@/components/LandingMozza/LandingMozza/Subjects/Desktop/DesktopSubjects';
import EducationalStages from '@/components/LandingMozza/LandingMozza/Subjects/EducationalStages/EducationalStages';
import Header from '@/components/LandingMozza/LandingMozza/Subjects/Header/Header';
import MobileSubjects from '@/components/LandingMozza/LandingMozza/Subjects/Mobile/MobileSubjects';
import {
  CotillonTopLeft,
  CotillonTopRight,
} from '@/components/LandingMozza/LandingMozza/Subjects/SubjectsCotillons';
import Button from '@/designSystem/Button/Button';

import messages from './messages';

import useStyles from './styles';

type Props = {
  isMobile?: boolean;
  handleRedirection: (
    _query?: Record<string, unknown>,
    _event?: string
  ) => void;
};

const Subjects: FC<Props> = ({ handleRedirection, isMobile }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  const handleClickSubject = (subject: string) => {
    handleRedirection({ subject }, 'searchSubject_landingSubjects');
  };
  const handleClickEducationalStage = (
    educationalStages: EducationalStage[]
  ) => {
    handleRedirection({ educationalStages }, 'searchLevel_landingSubjects');
  };
  const handleClickSeeTeachers = () => {
    handleRedirection({}, 'searchTeacher_landingSubjects');
  };

  return (
    <div className={classes.container}>
      <Cotillon top={-23} left={0}>
        <CotillonTopLeft />
      </Cotillon>
      <Cotillon top={88} right={-40}>
        <CotillonTopRight />
      </Cotillon>
      <div className={classes.header}>
        <Header />
        <EducationalStages handleRedirection={handleClickEducationalStage} />
      </div>
      {isMobile ? (
        <MobileSubjects handleClickSubject={handleClickSubject} />
      ) : (
        <DesktopSubjects handleClickSubject={handleClickSubject} />
      )}
      <div className={classes.buttonContainer}>
        <Button
          variant="primary"
          size="large"
          onClick={handleClickSeeTeachers}
          label={formatMessage(messages.button)}
        />
      </div>
    </div>
  );
};

export default Subjects;
