import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  page: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: `0 ${rem(12)}`,
    gap: rem(12),
  },
  containerStepper: {
    margin: '0 auto',
    width: 'fit-content',
  },
}));
