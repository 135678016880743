import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: rem(0), // for Navbar
    [breakpoints.up('md')]: {
      justifyContent: 'space-between',
      gap: rem(44),
      padding: `${rem(40)} 0 ${rem(30)} 0`, // for Navbar
    },
  },
  header: {
    marginBottom: rem(32),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    [breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  reasons: {
    display: 'none',
    [breakpoints.up('md')]: {
      listStyle: 'none',
      margin: `0 0 ${rem(40)}`,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
    },
  },
  neutralText: {
    color: palette.neutral[200],
  },
  displayInline: {
    display: 'inline',
  },
  imageContainer: {
    display: 'none',
    [breakpoints.up(1200)]: {
      display: 'block',
    },
  },
}));
