import { FC } from 'react';
import { useIntl } from 'react-intl';

import SeoHeader from '@/components/LandingMozza/common/SeoHeader/SeoHeader';
import SeoTable from '@/components/LandingMozza/common/SeoTable/SeoTable';
import {
  CITIES_LIST,
  LEVELS_LIST,
  SUBJECTS_LIST,
} from '@/components/LandingMozza/LandingMozza/SeoLinksLandingMozza/constants';

import messages from './messages';

const SeoLinksLandingMozza: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SeoHeader />
      <SeoTable
        links={SUBJECTS_LIST}
        title={formatMessage(messages.titleSubject)}
        type="subject"
      />
      <SeoTable
        links={LEVELS_LIST}
        title={formatMessage(messages.titleLevel)}
        type="subject"
      />
      <SeoTable
        links={CITIES_LIST}
        title={formatMessage(messages.titleCities)}
        type="location"
      />
    </>
  );
};

export default SeoLinksLandingMozza;
