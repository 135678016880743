import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import Icon from '@/designSystem/Icon/Icon';
import Input from '@/Molecules/Input/Input';
import Options from '@/Organisms/SearchBar/DrawerOptions/DrawerOptions';
import { orderLocationData } from '@/Organisms/SearchBar/utils';

import messages from './messages';
import useGoogleAutocomplete from './useGoogleAutocomplete';

import useStyles from './styles';

type Props = {
  setAddressQuery: Dispatch<SetStateAction<string>>;
};

const AddressInput: FC<Props> = ({ setAddressQuery }) => {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState();
  const [value] = useState(['Aucun résultat']);
  const [drawerOptions, setDrawerOptions] = useState([
    [{ text: 'Aucun résultat' }],
  ]);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const { options } = useGoogleAutocomplete(inputValue, value, true);

  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputRef?.current &&
        // eslint-disable-next-line spaced-comment
        //@ts-ignore
        !inputRef?.current?.contains(event.target)
      ) {
        setIsOptionOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    if (options?.length > 0) {
      const newOptions = orderLocationData(
        options
          .map((opt) => opt.description)
          .filter((opt) => opt !== undefined),
        inputValue
      );
      setDrawerOptions(
        newOptions?.length === 0 ? [[{ text: 'Aucun résultat' }]] : newOptions
      );
    } else {
      setDrawerOptions([[{ text: 'Aucun résultat' }]]);
    }
  }, [options, inputValue]);

  const onInputChange = (e) => {
    const input = e.target.value;
    setInputValue(input);
    if (input === '') {
      setIsOptionOpen(false);
    } else {
      setIsOptionOpen(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      const input = e.target.value;
      setInputValue(input);
      setAddressQuery(input);
      setIsOptionOpen(false);
    }
  };

  const onOptionClick = async (option) => {
    setInputValue(option);
    setAddressQuery(option);
    setIsOptionOpen(false);
  };

  return (
    <div className={classes.searchInput} ref={inputRef}>
      <Input
        placeholder={formatMessage(messages.address)}
        type="text"
        onKeyDown={onKeyDown}
        onChange={onInputChange}
        value={inputValue}
        containerClassName={classes.input}
        autoComplete="off"
      >
        <Input.Adornment variant="start">
          <Icon icon="location" />
        </Input.Adornment>
      </Input>
      {isOptionOpen && (
        <div className={classes.options}>
          <Options options={drawerOptions} onClick={onOptionClick} />
        </div>
      )}
    </div>
  );
};

export default AddressInput;
