import { FC } from 'react';
import { useIntl } from 'react-intl';
import { EducationalStage } from '@les-sherpas/sherpas-toolbox';

import Button from '@/designSystem/Button/Button';

import messages from './messages';

import useStyles from './styles';

type Props = {
  handleRedirection: (_educationalStages?: EducationalStage[]) => void;
};

const EducationalStages: FC<Props> = ({ handleRedirection }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      {[
        EducationalStage.College,
        EducationalStage.Lycee,
        EducationalStage.Prepa,
        EducationalStage.Superieur,
      ].map((key) => (
        <div key={key} className={classes.button}>
          <Button
            variant="tertiary"
            onClick={() => handleRedirection([key])}
            label={formatMessage(messages[key])}
          />
        </div>
      ))}
    </div>
  );
};

export default EducationalStages;
